import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-languages-selector',
  templateUrl: 'languages-selector.component.html',
  styleUrls: ['languages-selector.component.scss']
})
export class LanguagesSelectorComponent {
  @Input() availableLanguages: string[] = [];
  @Output() selectedLanguage: EventEmitter<string> = new EventEmitter<string>();

  selectedValue:string = 'ca';

  setSelectedLanguage(): void {

  }
}
