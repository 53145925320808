import { AuthenticationModule } from './authentication/authentication.module';
import { AppRoutingModule } from './app-routing.module';
import { LanguageModule } from './language/language.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpBackend, HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { ApiModule, BASE_PATH } from './services/cvdAPI';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { JwtInterceptor } from './certificate/jwt-interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ErrorModalComponent } from './shared/components/error-modal/error-modal.component';
import { SharedModule } from './shared/shared.module';

export function createTranslateLoader(httpBack: HttpBackend) {
  const path = window.location.origin + '/assets/i18n/';
  const http = new HttpClient(httpBack);

  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApiModule,
    SharedModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    LanguageModule,
    RouterModule,
    AuthenticationModule,
    NgbModule
  ],
  providers: [
    CookieService,
    {provide: BASE_PATH, useValue: environment.basePath},

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
