<nav class="navbar navbar-expand navbar-container">
  <a style="cursor: pointer;" class="nav-brand" (click)="navigateHome()">
    <img class="logo-salut"  src="/assets/images/logo-salut.svg" width="136.36px" height="40px" alt='Logo de Andalucia'>
  </a>
  <app-languages-selector
    class="languages-selector"
    [availableLanguages]="availableLanguages"
    (selectedLanguage)="selectedLanguage($event)">
  </app-languages-selector>
</nav>
