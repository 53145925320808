import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from './../shared/shared.module';
import {NgModule} from '@angular/core';
import {LanguagesSelectorComponent} from './components/languages-selector.component/languages-selector.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    LanguagesSelectorComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    TranslateModule.forChild(),
  ],
  exports: [
    LanguagesSelectorComponent
  ]
})
export class LanguageModule { }
