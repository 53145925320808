import { LanguageService } from './../../../language/services/language.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  availableLanguages: string[] = ['ca', 'es', 'en'];

  constructor(private _router: Router,
              private _language: LanguageService) { }

  ngOnInit(): void {
    this._language.setCurrentLanguage('ca');
    this.availableLanguages = this._language.getAvailableLanguages()
  }

  selectedLanguage(lang: string) {
    this._language.setCurrentLanguage(lang);
    this.availableLanguages = this._language.getAvailableLanguages();
  }

  navigateHome(): void {
    this._language.setCurrentLanguage('ca');
    this.availableLanguages = this._language.getAvailableLanguages()
      this._router.navigate(['/']).then( () => {
        window.location.reload();
      })
  }
}
