<div id="footer-container">
  <div class="container-fluid no-print">
      <div class="row footer-content-container" style="width: 100%">
        <div class="logo-container">
          <img class="logo-salut" src="assets/images/logo-salut.svg" width="75px" height="22px">
          <img class="logo-generalitat" src="/assets/images/logo-generalitat.svg" width="97px" height="25px">
        </div>
        <!-- <p class="privacy-policy">{{ "FOOTER.PRIVACY_POLICY" | translate }}</p> -->
      </div>
  </div>
</div>

