<div class="languages">
  <!-- <button mat-button class="languages__item" *ngFor="let lang of availableLanguages"
          (click)="selectedLanguage.emit(lang)">
    <span class="languages__item__text">{{ lang | uppercase }}</span>
  </button> -->
  <!-- <div class="dropdown-menu languages__item" *ngFor="let lang of availableLanguages">
    <span class="dropdown-item" (click)="selectedLanguage.emit(lang)">Idioma: {{lang | uppercase}}</span>
  </div> -->

  <mat-form-field>
    <mat-select [(ngModel)]="selectedValue" name="food">
      <div  *ngFor="let lang of availableLanguages"  >
      <mat-option *ngIf="lang == 'ca'" (click)="selectedLanguage.emit(lang)"[(value)]="selectedValue">
        {{ "HEADER.LANGUAGE_SELECTOR.CATALAN" | translate }}
      </mat-option>
      <mat-option *ngIf="lang == 'es'" (click)="selectedLanguage.emit(lang)"[(value)]="selectedValue">
        {{ "HEADER.LANGUAGE_SELECTOR.SPANISH" | translate }}
      </mat-option>
      <mat-option *ngIf="lang == 'en'" (click)="selectedLanguage.emit(lang)"[(value)]="selectedValue">
        {{ "HEADER.LANGUAGE_SELECTOR.ENGLISH" | translate }}
      </mat-option>
    </div>

    </mat-select>
  </mat-form-field>
</div>
