import { LanguageModule } from './../language/language.module';
import { RouterModule } from '@angular/router';
import {NgModule} from '@angular/core';
import {MaterialModule} from '../material/material.module';
import {TranslateModule} from '@ngx-translate/core';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgxCaptchaModule } from 'ngx-captcha';


@NgModule({
  declarations: [
    ErrorModalComponent,
    HeaderComponent,
    FooterComponent,
      ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    TranslateModule.forChild(),
    LanguageModule,
    NgxCaptchaModule
  ],
  exports: [
    ErrorModalComponent,
    HeaderComponent,
    FooterComponent,
    NgxCaptchaModule
  ],
  entryComponents: [
  ]
})
export class SharedModule { }
