import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  typeClicked: string;
  status: string;
  rule:string;
}

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  title:string;
  header:string;
  subHeader: string;
  body:string;
  status: boolean;
  textUrl1: string;
  url1: string;
  hyperlink1: string;
  textUrl2: string;
  url2: string;
  hyperlink2: string;


  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      console.log('data', this.data)
    }

  onNoClick(): void {
    this.dialogRef.close()
  }

  ngOnInit() {

    if(this.data.rule==='TECHNICAL' || this.data.status===undefined){
      switch(this.data.typeClicked){
        case 'OTP_INCORRECT':
          this.title='MODAL.ERROR_BACKEND';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          this.body='MODAL.OTP.BODY';
          break;
        case 'OTP_EXPIRED':
          this.title='MODAL.ERROR_BACKEND';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          this.body='MODAL.OTP.BODY';
          break;
        case 'OTP_USED':
          this.title='MODAL.ERROR_BACKEND';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          this.body='MODAL.OTP.BODY';
          break;
        case 'LOGIN':
          this.title='MODAL.ERROR_BACKEND';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          this.body='MODAL.LOGIN.BODY';
        break;
        case 'VACCINE':
          this.title='MODAL.VACCINE_ERROR.TITLE';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          break;
        case 'TEST':
          this.title='MODAL.PCR_ERROR.TITLE';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          break;
        case 'RECOVERY':
          this.title='MODAL.COVID_PASSED_ERROR.TITLE';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          break;
        case 'PDF':
          this.title='MODAL.PDF_ERROR.TITLE';
          this.header='MODAL.PDF_ERROR.HEADER';
          break;
      }
    }else if(this.data.status==='1056'){
      switch(this.data.typeClicked){
        case 'TEST':
          this.title='MODAL.NOT_APROVED_EMA_TEST.TITLE';
          this.header='MODAL.NOT_APROVED_EMA_TEST.HEADER';
          this.textUrl1 = 'MODAL.NOT_APROVED_EMA_TEST.TEXT_HYPERLINK_1';
          this.hyperlink1 = 'MODAL.NOT_APROVED_EMA_TEST.URL_HYPERLINK_1';
          this.url1 = 'https://canalsalut.gencat.cat/ca/salut-a-z/c/covid-19/conviure/certificat-covid-digital/';
          break;
        case 'RECOVERY':
          this.title='MODAL.NOT_APROVED_EMA_RECOVERY.TITLE';
          this.header='MODAL.NOT_APROVED_EMA_RECOVERY.HEADER';
          this.textUrl1 = 'MODAL.NOT_APROVED_EMA_RECOVERY.TEXT_HYPERLINK_1';
          this.hyperlink1 = 'MODAL.NOT_APROVED_EMA_RECOVERY.URL_HYPERLINK_1';
          this.url1 = 'https://canalsalut.gencat.cat/ca/salut-a-z/c/covid-19/conviure/certificat-covid-digital/';
          break;
      }
    } else {
    switch(this.data.typeClicked){
      case 'OTP_INCORRECT':
        this.title='MODAL.OTP_INCORRECT.TITLE';
        this.header='MODAL.OTP_INCORRECT.HEADER';
        this.body='MODAL.OTP_INCORRECT.BODY';
        break;
      case 'OTP_EXPIRED':
        this.title='MODAL.OTP_EXPIRED.TITLE';
        this.header='MODAL.OTP_EXPIRED.HEADER';
        this.body='MODAL.OTP_EXPIRED.BODY';
        break;
      case 'OTP_USED':
        this.title='MODAL.OTP_USED.TITLE';
        this.header='MODAL.OTP_USED.HEADER';
        this.body='MODAL.OTP_USED.BODY';
        break;
      case 'LOGIN':
        this.title='MODAL.LOGIN.TITLE';
        this.header='MODAL.LOGIN.HEADER';
        this.body='MODAL.LOGIN.BODY';
        break;
      case 'VACCINE':
        this.title='MODAL.VACCINE_ERROR.TITLE';
        this.header='MODAL.VACCINE_ERROR.HEADER';
        this.body='MODAL.VACCINE_ERROR.BODY';
        break;
      case 'TEST':
        this.title='MODAL.PCR_ERROR.TITLE';
        this.header='MODAL.PCR_ERROR.HEADER';
        this.subHeader = 'MODAL.PCR_ERROR.SUB_HEADER';
        this.textUrl1 = 'MODAL.PCR_ERROR.TEXT_HYPERLINK_1';
        this.hyperlink1 = 'MODAL.PCR_ERROR.URL_HYPERLINK_1';
        this.url1 = 'https://canalsalut.gencat.cat/ca/salut-a-z/c/coronavirus-2019-ncov/ciutadania/certificat-covid-digital-ue/';
        this.textUrl2 = 'MODAL.PCR_ERROR.TEXT_HYPERLINK_2';
        this.hyperlink2 = 'MODAL.PCR_ERROR.URL_HYPERLINK_2';
        this.url2 = 'https://canalsalut.gencat.cat/ca/salut-a-z/c/coronavirus-2019-ncov/ciutadania/certificat-covid-digital-ue/centres-acreditats/'
        break;
      case 'RECOVERY':
        this.title='MODAL.COVID_PASSED_ERROR.TITLE';
        this.header='MODAL.COVID_PASSED_ERROR.HEADER';
        this.textUrl1 = 'MODAL.COVID_PASSED_ERROR.TEXT_HYPERLINK_1';
        this.hyperlink1 = 'MODAL.COVID_PASSED_ERROR.URL_HYPERLINK_1';
        this.url1 = 'https://canalsalut.gencat.cat/ca/salut-a-z/c/coronavirus-2019-ncov/ciutadania/certificat-covid-digital-ue/';
        break;
      case 'PDF':
        this.title='MODAL.PDF_ERROR.TITLE';
        this.header='MODAL.PDF_ERROR.HEADER';
        break;
      case 'DISABLED':
        this.title='MODAL.DISABLED_TITLE';
        this.header='MODAL.DISABLED_BODY'
        break;
    }
  }
  }

}
