/**
 * Digital Green Passport
 * Digital Green Passport, the error handler is implement using   The IETF devised RFC 7807 effor, which creates a generalized error-handling schema. https://tools.ietf.org/html/rfc7807
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { CertificateRequest } from '../model/certificateRequest';
import { CertificateResponse } from '../model/certificateResponse';
import { Resource } from '../model/resource';
import { ResponseDataMapstringobject } from '../model/responseDataMapstringobject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DigitalGreenPassportService {

    protected basePath = '/';
    accessToken= sessionStorage.getItem('accessToken')
    public defaultHeaders = new HttpHeaders({'Content-Type':'application/json'});

   // public defaultHeaders = new HttpHeaders();

    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Pas 2 de la integració amb LMS
     *
     * @param CIP Paràmetre de tipus text que inclou el CIP de l’usuari de l’espai virtual
     * @param CODI_USER_CONNECTAT Paràmetre de tipus text que inclou l’identificador de l’usuari actiu
     * @param DN Paràmetre que conté el certificat digital de l’usuari connectat en base64. Aquest paràmetre només s’envia, en cas que l’usuari s’hagi identificat a LMS amb certificat. Sempre serà de l’usuari actiu
     * @param DNI Paràmetre de tipus text que inclou el document d’identificació l’usuari de l’espai virtual. Si és menor, pot no tenir document d’identificació
     * @param FIRMA LMS firmarà la concatenació de la cadena de text “CIP i DNI” amb aquest format: CIP&#x3D;abcd&amp;DNI&#x3D;00000000T
     * @param IDIOMA Idioma de connexió amb els valors possibles: ca | es | oc
     * @param NOM_USER_CONNECTAT Paràmetre de tipus text que inclou el nom i cognoms de l’usuari actiu
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptUsingPOST(CIP: string, CODI_USER_CONNECTAT: string, DN: string, DNI: string, FIRMA: string, IDIOMA: string, NOM_USER_CONNECTAT: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public acceptUsingPOST(CIP: string, CODI_USER_CONNECTAT: string, DN: string, DNI: string, FIRMA: string, IDIOMA: string, NOM_USER_CONNECTAT: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public acceptUsingPOST(CIP: string, CODI_USER_CONNECTAT: string, DN: string, DNI: string, FIRMA: string, IDIOMA: string, NOM_USER_CONNECTAT: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public acceptUsingPOST(CIP: string, CODI_USER_CONNECTAT: string, DN: string, DNI: string, FIRMA: string, IDIOMA: string, NOM_USER_CONNECTAT: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (CIP === null || CIP === undefined) {
            throw new Error('Required parameter CIP was null or undefined when calling acceptUsingPOST.');
        }

        if (CODI_USER_CONNECTAT === null || CODI_USER_CONNECTAT === undefined) {
            throw new Error('Required parameter CODI_USER_CONNECTAT was null or undefined when calling acceptUsingPOST.');
        }

        if (DN === null || DN === undefined) {
            throw new Error('Required parameter DN was null or undefined when calling acceptUsingPOST.');
        }

        if (DNI === null || DNI === undefined) {
            throw new Error('Required parameter DNI was null or undefined when calling acceptUsingPOST.');
        }

        if (FIRMA === null || FIRMA === undefined) {
            throw new Error('Required parameter FIRMA was null or undefined when calling acceptUsingPOST.');
        }

        if (IDIOMA === null || IDIOMA === undefined) {
            throw new Error('Required parameter IDIOMA was null or undefined when calling acceptUsingPOST.');
        }

        if (NOM_USER_CONNECTAT === null || NOM_USER_CONNECTAT === undefined) {
            throw new Error('Required parameter NOM_USER_CONNECTAT was null or undefined when calling acceptUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (CIP !== undefined && CIP !== null) {
            queryParameters = queryParameters.set('CIP', <any>CIP);
        }
        if (CODI_USER_CONNECTAT !== undefined && CODI_USER_CONNECTAT !== null) {
            queryParameters = queryParameters.set('CODI_USER_CONNECTAT', <any>CODI_USER_CONNECTAT);
        }
        if (DN !== undefined && DN !== null) {
            queryParameters = queryParameters.set('DN', <any>DN);
        }
        if (DNI !== undefined && DNI !== null) {
            queryParameters = queryParameters.set('DNI', <any>DNI);
        }
        if (FIRMA !== undefined && FIRMA !== null) {
            queryParameters = queryParameters.set('FIRMA', <any>FIRMA);
        }
        if (IDIOMA !== undefined && IDIOMA !== null) {
            queryParameters = queryParameters.set('IDIOMA', <any>IDIOMA);
        }
        if (NOM_USER_CONNECTAT !== undefined && NOM_USER_CONNECTAT !== null) {
            queryParameters = queryParameters.set('NOM_USER_CONNECTAT', <any>NOM_USER_CONNECTAT);
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<string>(`${this.basePath}/sso/accept`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pas 1 de la integració amb LMS
     *
     * @param CIP Paràmetre de tipus text que inclou el CIP de l’usuari de l’espai virtual
     * @param CODI_USER_CONNECTAT Paràmetre de tipus text que inclou l’identificador de l’usuari actiu
     * @param DN Paràmetre que conté el certificat digital de l’usuari connectat en base64. Aquest paràmetre només s’envia, en cas que l’usuari s’hagi identificat a LMS amb certificat. Sempre serà de l’usuari actiu
     * @param DNI Paràmetre de tipus text que inclou el document d’identificació l’usuari de l’espai virtual. Si és menor, pot no tenir document d’identificació
     * @param FIRMA LMS firmarà la concatenació de la cadena de text “CIP i DNI” amb aquest format: CIP&#x3D;abcd&amp;DNI&#x3D;00000000T
     * @param IDIOMA Idioma de connexió amb els valors possibles: ca | es | oc
     * @param NOM_USER_CONNECTAT Paràmetre de tipus text que inclou el nom i cognoms de l’usuari actiu
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public challengeUsingPOST(CIP: string, CODI_USER_CONNECTAT: string, DN: string, DNI: string, FIRMA: string, IDIOMA: string, NOM_USER_CONNECTAT: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public challengeUsingPOST(CIP: string, CODI_USER_CONNECTAT: string, DN: string, DNI: string, FIRMA: string, IDIOMA: string, NOM_USER_CONNECTAT: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public challengeUsingPOST(CIP: string, CODI_USER_CONNECTAT: string, DN: string, DNI: string, FIRMA: string, IDIOMA: string, NOM_USER_CONNECTAT: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public challengeUsingPOST(CIP: string, CODI_USER_CONNECTAT: string, DN: string, DNI: string, FIRMA: string, IDIOMA: string, NOM_USER_CONNECTAT: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (CIP === null || CIP === undefined) {
            throw new Error('Required parameter CIP was null or undefined when calling challengeUsingPOST.');
        }

        if (CODI_USER_CONNECTAT === null || CODI_USER_CONNECTAT === undefined) {
            throw new Error('Required parameter CODI_USER_CONNECTAT was null or undefined when calling challengeUsingPOST.');
        }

        if (DN === null || DN === undefined) {
            throw new Error('Required parameter DN was null or undefined when calling challengeUsingPOST.');
        }

        if (DNI === null || DNI === undefined) {
            throw new Error('Required parameter DNI was null or undefined when calling challengeUsingPOST.');
        }

        if (FIRMA === null || FIRMA === undefined) {
            throw new Error('Required parameter FIRMA was null or undefined when calling challengeUsingPOST.');
        }

        if (IDIOMA === null || IDIOMA === undefined) {
            throw new Error('Required parameter IDIOMA was null or undefined when calling challengeUsingPOST.');
        }

        if (NOM_USER_CONNECTAT === null || NOM_USER_CONNECTAT === undefined) {
            throw new Error('Required parameter NOM_USER_CONNECTAT was null or undefined when calling challengeUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (CIP !== undefined && CIP !== null) {
            queryParameters = queryParameters.set('CIP', <any>CIP);
        }
        if (CODI_USER_CONNECTAT !== undefined && CODI_USER_CONNECTAT !== null) {
            queryParameters = queryParameters.set('CODI_USER_CONNECTAT', <any>CODI_USER_CONNECTAT);
        }
        if (DN !== undefined && DN !== null) {
            queryParameters = queryParameters.set('DN', <any>DN);
        }
        if (DNI !== undefined && DNI !== null) {
            queryParameters = queryParameters.set('DNI', <any>DNI);
        }
        if (FIRMA !== undefined && FIRMA !== null) {
            queryParameters = queryParameters.set('FIRMA', <any>FIRMA);
        }
        if (IDIOMA !== undefined && IDIOMA !== null) {
            queryParameters = queryParameters.set('IDIOMA', <any>IDIOMA);
        }
        if (NOM_USER_CONNECTAT !== undefined && NOM_USER_CONNECTAT !== null) {
            queryParameters = queryParameters.set('NOM_USER_CONNECTAT', <any>NOM_USER_CONNECTAT);
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/html'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<string>(`${this.basePath}/sso/challenge`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Obtain a new access/refresh pair of tokens
     *
     * @param token A refresh token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshTokensUsingPOST(token: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseDataMapstringobject>;
    public refreshTokensUsingPOST(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseDataMapstringobject>>;
    public refreshTokensUsingPOST(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseDataMapstringobject>>;
    public refreshTokensUsingPOST(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling refreshTokensUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResponseDataMapstringobject>(`${this.basePath}/sso/refresh`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request Digital Green Certificate
     *
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestCertificatePDFUsingPOST(request: CertificateRequest, observe?: 'body', reportProgress?: boolean): Observable<Resource>;
    public requestCertificatePDFUsingPOST(request: CertificateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Resource>>;
    public requestCertificatePDFUsingPOST(request: CertificateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Resource>>;
    public requestCertificatePDFUsingPOST(request: CertificateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling requestCertificatePDFUsingPOST.');
        }

        let accessToken= sessionStorage.getItem('accessTokenOTP')
        let headers = new HttpHeaders({'Content-Type':'application/json',Authorization: 'Bearer '+accessToken});



        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Resource>(`${this.basePath}/lobby/v1/certificate/pdf`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }

    /**
     * Request Digital Green Certificate
     *
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestCertificateUsingPOST(request: CertificateRequest, observe?: 'body', reportProgress?: boolean): Observable<CertificateResponse>;
    public requestCertificateUsingPOST(request: CertificateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateResponse>>;
    public requestCertificateUsingPOST(request: CertificateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateResponse>>;
    public requestCertificateUsingPOST(request: CertificateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling requestCertificateUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // authentication (JWT) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CertificateResponse>(`${this.basePath}/lobby/v1/certificate`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
     * Obtain a new access of tokens to Labs
     *
     * @param CIP Paràmetre de tipus text que inclou el CIP de l’usuari de l’espai virtual
     * @param clientId clientId
     * @param DNI Paràmetre de tipus text que inclou el document d’identificació l’usuari de l’espai virtual. Si és menor, pot no tenir document d’identificació
     * @param secret secret
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
      public labGenerateTokenUsingPOST(CIP: string, clientId: string, DNI: string, secret: string, type: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseDataMapstringobject>;
      public labGenerateTokenUsingPOST(CIP: string, clientId: string, DNI: string, secret: string, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseDataMapstringobject>>;
      public labGenerateTokenUsingPOST(CIP: string, clientId: string, DNI: string, secret: string, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseDataMapstringobject>>;
      public labGenerateTokenUsingPOST(CIP: string, clientId: string, DNI: string, secret: string, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

          if (CIP === null || CIP === undefined) {
              throw new Error('Required parameter CIP was null or undefined when calling labGenerateTokenUsingPOST.');
          }

          if (clientId === null || clientId === undefined) {
              throw new Error('Required parameter clientId was null or undefined when calling labGenerateTokenUsingPOST.');
          }

          if (DNI === null || DNI === undefined) {
              throw new Error('Required parameter DNI was null or undefined when calling labGenerateTokenUsingPOST.');
          }

          if (secret === null || secret === undefined) {
              throw new Error('Required parameter secret was null or undefined when calling labGenerateTokenUsingPOST.');
          }

          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          if (CIP !== undefined && CIP !== null) {
              queryParameters = queryParameters.set('CIP', <any>CIP);
          }
          if (DNI !== undefined && DNI !== null) {
              queryParameters = queryParameters.set('DNI', <any>DNI);
          }

          let headers = this.defaultHeaders;
          if (clientId !== undefined && clientId !== null) {
              headers = headers.set('clientId', String(clientId));
          }
          if (secret !== undefined && secret !== null) {
              headers = headers.set('secret', String(secret));
          }

          if(type !== undefined && type !== null) {
            queryParameters = queryParameters.set('TYPE', String(type));
          }

          // authentication (JWT) required
          if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
              headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
          }

          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
              'application/json'
          ];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set('Accept', httpHeaderAcceptSelected);
          }

          // to determine the Content-Type header
          const consumes: string[] = [
              'application/json'
          ];

          return this.httpClient.post<ResponseDataMapstringobject>(`${this.basePath}/sso/labs/generateToken`,
              null,
              {
                  params: queryParameters,
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }

    /**
     * Generate a pdf from an already generated certificate
     *
     * @param certificateType certificateType
     * @param previousResponse previousResponse
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public createCertificatePDFUsingPUT(certificateType: string, previousResponse: CertificateResponse, observe?: 'body', reportProgress?: boolean): Observable<Resource>;
     public createCertificatePDFUsingPUT(certificateType: string, previousResponse: CertificateResponse, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Resource>>;
     public createCertificatePDFUsingPUT(certificateType: string, previousResponse: CertificateResponse, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Resource>>;
     public createCertificatePDFUsingPUT(certificateType: string, previousResponse: CertificateResponse, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

         if (certificateType === null || certificateType === undefined) {
             throw new Error('Required parameter certificateType was null or undefined when calling createCertificatePDFUsingPUT.');
         }

         if (previousResponse === null || previousResponse === undefined) {
             throw new Error('Required parameter previousResponse was null or undefined when calling createCertificatePDFUsingPUT.');
         }

         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (certificateType !== undefined && certificateType !== null) {
             queryParameters = queryParameters.set('certificateType', <any>certificateType);
         }

         let accessToken= sessionStorage.getItem('accessTokenOTP')
        let headers = new HttpHeaders({'Content-Type':'application/json',Authorization: 'Bearer '+accessToken});

        // let headers = this.defaultHeaders;

         // authentication (JWT) required
         if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
             headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
         }

         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/pdf'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }

         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }

         return this.httpClient.put<Resource>(`${this.basePath}/lobby/v1/certificate/pdf`,
             previousResponse,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress,
                 responseType: 'blob' as 'json'

             }
         );
     }

}
